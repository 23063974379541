<template>
  <div>
    <keep-alive>
      <router-view :key="$route.fullPath" class="tab-view" v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view :key="$route.fullPath" class="tab-view" v-if="!$route.meta.keepAlive"></router-view>
    <van-tabbar v-show="$route.meta.navshow" placeholder fixed v-model="active" active-color="#FE5E03">
      <van-tabbar-item name="Home">
        <span>首页</span>
        <template #icon="props">
          <img :src="props.active ? home.active : home.inactive" @click="onBarClick('Home')" />
        </template>
      </van-tabbar-item>
      <van-popover v-model="popover" close-on-click-action close-on-click-outside trigger="click" placement="top">
        <van-cell title="查资料" border is-link v-if="menuDisplay('Document')" @click="onPopoverClick('Document')" />
        <van-cell title="查院校" border is-link v-if="menuDisplay('School')" @click="onPopoverClick('School')" />
        <van-cell title="查专业" border is-link v-if="menuDisplay('Major')" @click="onPopoverClick('Major')" />
        <van-cell title="查资讯" border is-link v-if="menuDisplay('Article')" @click="onPopoverClick('Article')" />
        <van-cell title="AI择校" border is-link v-if="menuDisplay('AiSelection')" @click="onPopoverClick('AiSelection')" />
        <van-cell title="智慧调剂" border is-link v-if="menuDisplay('SmartAdjust')" @click="onPopoverClick('SmartAdjust')" />
        <van-cell title="估分排名" border is-link v-if="menuDisplay('GradeRank')" @click="onPopoverClick('GradeRank')" />
        <van-cell title="报录数据" border is-link v-if="menuDisplay('Admit')" @click="onPopoverClick('Admit')" />
        <van-cell title="调剂公告" border is-link v-if="menuDisplay('Adjust')" @click="onPopoverClick('Adjust')" />
        <van-cell title="视频网课" border is-link v-if="menuDisplay('Course')" @click="onPopoverClick('Course')" />
        <van-cell title="国家线" border is-link v-if="menuDisplay('NationalLine')" @click="onPopoverClick('NationalLine')" />
        <van-cell title="加盟代理" border is-link v-if="menuDisplay('League')" @click="onPopoverClick('League')" />
        <template #reference>
          <van-tabbar-item name="Other">
            <span>考研工具</span>
            <template #icon="props">
              <img :src="props.active ? check.active : check.inactive" />
            </template>
          </van-tabbar-item>
        </template>
      </van-popover>
      <van-tabbar-item name="Contact">
        <span>客服</span>
        <template #icon="props">
          <img :src="props.active ? contact.active : contact.inactive" @click="onBarClick('Contact')" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="OrderList">
        <span>订单</span>
        <template #icon="props">
          <img :src="props.active ? order.active : order.inactive" @click="onBarClick('OrderList')" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="Account">
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? account.active : account.inactive" @click="onBarClick('Account')" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import Tools from "@/tool";
import Config from "@/api/service";

export default {
  name: "PCModule",
  components: {
  },
  props: {
  },
  data() {
    return {
      active: 'Home',
      popover: false,
      menuPermissions: [],
      home: {
        active: require('@/assets/images/mt/home-a.png'),
        inactive: require('@/assets/images/mt/home-b.png'),
      },
      search: {
        active: require('@/assets/images/mt/search-a.png'),
        inactive: require('@/assets/images/mt/search-b.png'),
      },
      check: {
        active: require('@/assets/images/mt/check-a.png'),
        inactive: require('@/assets/images/mt/check-b.png'),
      },
      contact: {
        active: require('@/assets/images/mt/contact-a.png'),
        inactive: require('@/assets/images/mt/contact-b.png'),
      },
      order: {
        active: require('@/assets/images/mt/order-a.png'),
        inactive: require('@/assets/images/mt/order-b.png'),
      },
      account: {
        active: require('@/assets/images/mt/account-a.png'),
        inactive: require('@/assets/images/mt/account-b.png'),
      },
    };
  },
  computed: {
    menuDisplay() {
      return function (code) {
        if (this.menuPermissions && this.menuPermissions.includes(code)) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  methods: {
    getProxyInfo() {
      var model = {
        ProxyCode: this.$store.state.proxyCode,
      };
      return this.$axios
        .post(Config.proxy.info, model)
        .then((res) => {
          //权限
          this.menuPermissions = res.data.Data.MenuPermissions;
          //设置标题
          if (this.$route.name != "Course1" && this.$route.name != "Document1") {
            window.document.title = res.data.Data.WebsiteTitle;
          }
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    onBarClick(name) {
      this.$router.push({ name: name, params: { proxyCode: this.$store.state.proxyCode } });
    },
    onPopoverClick(name) {
      this.popover = false;
      this.$router.push({ name: name, params: { proxyCode: this.$store.state.proxyCode } });
    }
  },
  mounted() {
    if (this.$route.name == 'Home') {
      this.active = 'Home';
    } else if (this.$route.name == 'Contact') {
      this.active = 'Contact';
    } else if (this.$route.name == 'OrderList') {
      this.active = 'OrderList';
    } else if (this.$route.name == 'Account') {
      this.active = 'Account';
    } else {
      this.active = 'Other';
    }
    this.getProxyInfo();
  }
};
</script>
<style scoped>
.tab-view {
  background-color: #F6F6F6;
  min-height: calc(100% - 50px);
}

.van-popover__wrapper {
  display: flex;
  flex: 1;
}
</style>